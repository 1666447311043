<template>
  <v-container fluid>
    <v-row v-if="!editCard">
      <v-col>
        <div>
          <span class="mr-1">ID:</span>
          <span class="font-weight-medium">{{ currentItem.id }}</span>
        </div>
        <div>
          <span class="mr-1">Орендодавець:</span>
          <router-link
            v-if="currentItem.balance_holder"
            :to="{ name: 'PartnerCard', params: { id: currentCompanyId, partnerId: currentItem.balance_holder.id } }"
            class="font-weight-medium text-decoration-none"
          >
            {{ currentItem.balance_holder.name }}
          </router-link>
          <span v-else class="red--text">Відкріплено</span>
        </div>
        <div>
          <span class="mr-1">Назва:</span>
          <span class="font-weight-medium">{{ currentItem.name }}</span>
        </div>
        <div>
          Місце розташування:
          <template v-if="currentItem.location.length">
            <div v-for="(location, index) in currentItem.location" :key="index">
              <span class="ml-4 mr-1">{{ index + 1 }}.</span>
              <span class="font-weight-medium">{{ location }}</span>
            </div>
          </template>
          <span v-else class="ml-1 font-weight-medium">без локації</span>
        </div>
        <div>
          <span class="mr-1">Кількість поверхів:</span>
          <span class="font-weight-medium">{{ currentItem.floor_count }}</span>
        </div>
        <div>
          <span class="mr-1">Кількість жителів:</span>
          <span class="font-weight-medium">{{ currentItem.habitants_count }}</span>
        </div>
      </v-col>
      <v-col>
        <div>
          <span class="mr-1">Графік роботи:</span>
          <span class="font-weight-medium">{{ currentItem.schedule }}</span>
        </div>
        <div>
          <span class="mr-1">Доступність:</span>
          <span class="font-weight-medium">{{ currentItem.availability ? 'Відкрито' : 'Закрито' }}</span>
        </div>
        <div>
          <span class="mr-1">Опис:</span>
          <span class="font-weight-medium">{{ currentItem.description }}</span>
        </div>
      </v-col>
      <v-col>
        <v-switch
          v-model="currentItem.is_searchable"
          class="mt-0"
          label="Відображається в мобільному додатку"
          color="success"
          disabled
          hide-details
        ></v-switch>
        <div class="mt-12">
          <QrCode :qrLink="qrLink" :qrCodeData="qrCodeData" />
        </div>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col>
        <div>
          <span class="mr-1">ID:</span>
          <span class="font-weight-medium">{{ editedItem.id }}</span>
        </div>
        <div>
          <span class="mr-1">Орендодавець:</span>
          <span class="font-weight-medium">{{ editedItem.balance_holder.name }}</span>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-1">Назва:</span>
          <v-text-field v-model="editedItem.name" class="" dense hide-details></v-text-field>
        </div>
        <div class="mt-2">
          <span class="mr-1">Місце розташування:</span>
          <!-- noinspection JSUnusedLocalSymbols -->
          <div v-for="(location, index) in editedItem.location" :key="index" class="d-flex align-baseline custom-field">
            <span class="ml-4 mr-1">{{ index + 1 }}.</span>
            <v-text-field
              v-model="editedItem.location[index]"
              append-outer-icon="mdi-delete-outline"
              @click:append-outer="removeLocation(index)"
              class=""
              dense
              hide-details
            ></v-text-field>
          </div>
          <div class="custom-field">
            <v-btn @click="addLocation" class="d-block ml-auto mt-3" x-small>Додати Місце</v-btn>
          </div>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-1">Кількість поверхів:</span>
          <v-text-field v-model.number="editedItem.floor_count" class="" dense hide-details></v-text-field>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-1">Кількість жителів:</span>
          <v-text-field v-model.number="editedItem.habitants_count" class="" dense hide-details></v-text-field>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-1">Графік роботи:</span>
          <v-text-field v-model="editedItem.schedule" class="" dense hide-details></v-text-field>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-1">Доступність:</span>
          <v-select v-model="editedItem.availability" :items="availItems" item-text="text" item-value="value"></v-select>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-1">Опис:</span>
          <v-textarea v-model="editedItem.description" class="" dense hide-details></v-textarea>
        </div>
      </v-col>
      <v-col>
        <v-switch
          v-model="editedItem.is_searchable"
          class="mt-0"
          label="Відображати в мобільному додатку"
          color="success"
          hide-details
        ></v-switch>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-divider v-if="!editCard" class="mb-2"></v-divider>
        <CardAddress
          type="servicePoint"
          :entityId="editedItem.id"
          :is-data-saved="isDataSaved"
          :edit-card="editCard"
        ></CardAddress>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import cloneDeep from '@/mixins/cloneDeep'
import convertAddress from '@/mixins/convertAddress'
import { ROUTER_LINKS } from '@/const/router-links.enum'
export default {
  name: 'Info',

  mixins: [cloneDeep, convertAddress],

  components: {
    CardAddress: () => import('@/components/partials/CardAddress'),
    QrCode: () => import('@/components/common/QrCode'),
  },

  props: {
    currentItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentTabName: {
      required: true,
      default: null,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    onlineBaseURL: process.env.VUE_APP_ONLINE_PUBLIC_URL,
    editedItem: {},
    availItems: [
      { text: 'Закрито', value: false },
      { text: 'Відкрито', value: true },
    ],
    qrLink: '',
  }),

  computed: {
    currentCompanyId() {
      return this.$route.params.id
    },
    qrCodeData() {
      return {
        id: this.currentItem.id,
        address: this.currentItem.address,
        name: this.currentItem.name,
      }
    },
  },

  watch: {
    isDataSaved(val) {
      if (val && this.currentTabName === 'info') {
        this.$emit('saveData', this.editedItem)
      }
    },
    editCard(val) {
      if (!val) this.initialize()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.editedItem = this.cloneObjectDeep(this.currentItem)
      this.qrLink = `${this.onlineBaseURL}/?companyId=${this.currentCompanyId}&spId=${this.currentItem.id}`
    },
    addLocation() {
      this.editedItem.location.push('')
    },
    removeLocation(index) {
      this.editedItem.location.splice(index, 1)
    },
    /*loadQR() {
      this.qrLink = `https://online.cloudmate.info/?companyId=${this.currentCompanyId}&spId=${this.currentItem.id}`
      await this.loadQrCode(payload)
      const blob = new Blob([this.file], { type: 'image/png' })
      this.fileUrl = URL.createObjectURL(blob)
    },*/
  },
}
</script>

<style scoped lang="scss">
.custom-field {
  max-width: 500px !important;
}
</style>
